<template>
  <div
    class="swiper-slide"
    @click="toggleAnswer(Board.bordNo)"
    style="cursor: pointer"
  >
    <div class="notice-item">
      <p class="q1">
        <span v-if="Board.propCd === '1'">공지사항</span>
        <span v-if="Board.propCd === '2'">이벤트&#38;뉴스</span>
      </p>
      <p class="q2">
        <span>{{ `${Board.bordNm}` }}</span>
      </p>
      <p v-if="isNoticeWidth" class="q3">
        <span>등록일자 : {{ `${formatDate(Board.regDt)}` }}</span>
      </p>
      <p v-if="isNoticeWidth" class="q4">
        <span>조회수 :{{ `${Board.readCnt}` }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    Board: {
      type: Object,
    },
  },
  data() {
    return {
      isNoticeWidth: false,
      bordNum: null,
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  watch: {
    window: {
      deep: true,
      handler() {
        if (this.window.width < 1023) {
          this.isNoticeWidth = false
        } else {
          this.isNoticeWidth = true
        }
      },
    },
  },
  computed: {},
  methods: {
    toggleAnswer(bordNo) {
      if (bordNo === 'close') {
        this.bordNum = null
      } else {
        this.bordNum = bordNo
      }
      this.$emit('toggleAnswer', this.bordNum)
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style></style>
