<template>
  <div
    v-if="bordNum === BoardDetail.bordNo"
    class="noticeDetail-con"
    :class="{ on: bordNum === BoardDetail.bordNo }"
  >
    <div class="a noticeDetail">
      <div class="bordCont-head">
        <p class="noticeDetail-cnt">{{ `조회수 : ${BoardDetail.readCnt}` }}</p>
        <p class="noticeDetail-title" v-html="BoardDetail.bordNm"></p>
        <p class="noticeDetail-regDt">
          {{ `등록일자 : ${formatDate(BoardDetail.regDt)}` }}
        </p>
      </div>
      <div class="bordCont-Content">
        <div v-html="BoardDetail.bordCont"></div>
      </div>
      <div class="bordCont-bottom myp-btn-wrap">
        <button type="button" class="btn-save01" @click="toggleAnswer('close')">
          닫기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  components: {},
  props: {
    BoardDetail: {
      type: Object,
    },
    bordNum: null,
  },
  data() {
    return {
      bordNumber: this.bordNum,
    }
  },
  computed: {},
  watch: {},
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    toggleAnswer(bordNo) {
      if (bordNo === 'close') {
        this.bordNumber = null
      } else {
        this.bordNumber = bordNo
      }
      this.$emit('toggleAnswer', this.bordNumber)
    },
  },
  created() {
    // console.log('Detail', this.BoardDetail.bordNo)
  },
  updated() {},
}
</script>

<style></style>
