<template>
  <div class="page-item-wrap">
    <vue-confirm-dialog
      v-if="isModalViewed"
      cookie-key="ModalView"
    ></vue-confirm-dialog>
    <!-- <slot name="search-aside"></slot> -->
    <main class="main">
      <background-image></background-image>
      <!-- <slot name="search-bar"></slot> -->
      <main-job-swiper :list="bannerList"></main-job-swiper>
      <NoticeRolling></NoticeRolling>
      <swiper-nav @search="onAllSearch" :userId="userId"></swiper-nav>
      <special-job-list
        v-if="specialList && specialList.length"
        title="스페셜 채용"
        class="special"
        :list="specialList"
      ></special-job-list>
      <special-job-list
        v-if="urgentList && urgentList.length"
        title="긴급 채용"
        class="emergency"
        :list="urgentList"
      ></special-job-list>
      <normal-job-list :list="generalList" @search="onSearch"></normal-job-list>
    </main>
  </div>
</template>

<script>
import BackgroundImage from 'Components/find/partials/backgroundImage'
import MainJobSwiper from 'Components/find/content/MainJobSwiper'
import SpecialJobList from 'Components/find/content/SpecialJobList'
import NormalJobList from 'Components/find/content/NormalJobList'
import swiperNav from 'Components/find/partials/swiperNav'
import NoticeRolling from 'Components/community/content/NoticeRolling'
import findJobMixin from 'Mixins/findJob'
import { mapGetters, mapMutations } from 'vuex'
// import '../utils/fcm'
// import {} from '../../public/firebase-messaging-sw'
export default {
  components: {
    BackgroundImage,
    MainJobSwiper,
    SpecialJobList,
    NormalJobList,
    swiperNav,
    NoticeRolling,
  },
  mixins: [findJobMixin],
  data() {
    return {
      rightChk: true,
      isModalViewed: false,
      vapidKey: '',
      currentToken: '',
    }
  },
  mounted() {
    if (this.$cookies.isKey('ModalView')) {
      this.isModalViewed = false
    } else {
      this.isModalViewed = true
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'account', 'userId', 'latitude', 'longitude']),
    // browserName() {
    //   const agent = window.navigator.userAgent.toLowerCase()

    //   if (agent.indexOf('edge') > -1) {
    //     return ['MS Edge'] //MS 엣지
    //   } else if (agent.indexOf('edg/') > -1) {
    //     return ['Edge(chrominum based)'] //크롬 기반 엣지
    //   } else if (agent.indexOf('opr') > -1) {
    //     return ['Opera'] //오페라
    //   } else if (agent.indexOf('whale') > -1) {
    //     return ['whale'] //네이버 웨일
    //   } else if (agent.indexOf('chrome') > -1) {
    //     return ['chrome'] //크롬
    //   } else if (agent.indexOf('safari') > -1) {
    //     return ['Safari'] //사파리
    //   } else if (agent.indexOf('trident') > -1) {
    //     return ['MS IE'] //익스플로러
    //   } else if (agent.indexOf('firefox') > -1) {
    //     return ['Mozilla Firefox'] //파이어 폭스
    //   }
    //   return ['other']
    // },
  },

  methods: {
    ...mapMutations(['SET_LATITUDE', 'SET_LONGITUDE']),
    url() {
      console.log(window.location.href)
    },
    // permissionDialog() {
    //   Notification.requestPermission().then(permission => {
    //     console.log('permission=>', permission)
    //     if (permission === 'default' || permission === 'denied') {
    //       this.$confirm({
    //         message:
    //           '알림이 설정되어 있지 않습니다.' +
    //           `\n` +
    //           '알림을 설정하시겠습니까?',
    //         button: {
    //           yes: '예',
    //           no: '일주일간 보지 않기',
    //         },
    //         /**
    //          * Callback Function
    //          * @param {Boolean} confirm
    //          */
    //         callback: confirm => {
    //           if (confirm) {
    //             subscribe()
    //           } else {
    //             this.$cookies.set('rightChk', 'Y', '7d')
    //             this.rightChk = false
    //             this.$emit('ok')
    //           }
    //         },
    //       })
    //     }
    //   })
    // },
    onSearch(query) {
      console.info('onSearch query : ', query)
      this.schRegion = query.schRegion
      this.schPosition = query.schPosition
      this.fetchGeneralList()
    },
    onAllSearch(query) {
      // console.info('onAllSearch query : ', query)
      //console.info('onSearch query : ', query.schCompanyId)
      this.schRegion = query.schRegion
      this.schPosition = query.schPosition
      this.schWorkPeriod = query.schWorkPeriod
      this.schCompanyId = query.schCompanyId
      this.schPushStatus = query.schPushStatus
      this.distance = query.distance
      this.fetchSpecialList(0, 20)
      this.fetchUrgentList(0, 20)
      this.fetchGeneralList()
      console.info('onAllSearch', query)
    },
  },
  async created() {
    // await this.permissionDialog()
    if (this.$cookies.isKey('ModalView')) {
      this.isModalViewed = false
    } else {
      this.isModalViewed = true
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.$store.commit('SET_LATITUDE', position.coords.latitude)
          this.$store.commit('SET_LONGITUDE', position.coords.longitude)
          //console.log('created.getCurrentPosition=', position.coords.latitude)
          //console.log('created.getCurrentPosition=', position.coords.longitude)
        },
        error => {
          if (error.message == 'User denied Geolocation') {
            // alert(
            //   '위치 정보 엑세스 허용을 하시면 업체와의 거리를 확인 하실 수 있습니다.',
            // )
          }
          console.log(error.message)
        },
      )
    } else {
      console.log('GPS를 지원하지 않습니다')
    }
  },
}
</script>

<style>
.vc-container {
  width: 420px;
}
.vc-text {
  white-space: pre-line;
}
</style>
