<template>
  <section id="noticeRolling">
    <p id="more" @click="noticeRoute">&#43;more</p>
    <div
      v-if="noticeSlides"
      class="notice-slides swiper-container notice_list"
      id="noticeSlides"
    >
      <div class="swiper-wrapper">
        <notice-rolling-item
          v-for="(BoardItem, idx) in BoardRolList"
          :key="`BoardItem_${idx}`"
          :Board="BoardItem"
          @toggleAnswer="toggleAnswe"
        ></notice-rolling-item>
        <div class="none-con" v-if="BoardRolList.length === 0">
          <p class="noneText">등록된 게시글이 없습니다.</p>
        </div>
      </div>
    </div>
    <div id="noticeDetail">
      <notice-rolling-detail
        v-for="(BoardDetail, index) in Boards"
        :key="`BoardDetail${index}`"
        :BoardDetail="BoardDetail"
        :bordNum="bordNum"
        @toggleAnswer="toggleAnswe"
      ></notice-rolling-detail>
    </div>
  </section>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import NoticeRollingItem from 'Components/community/partials/NoticeRollingItem'
import NoticeRollingDetail from 'Components/community/partials/NoticeRollingDetail'
import { getBoardsList, getUpdateReadCnt } from 'Api/modules'
import _ from 'lodash'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'

export default {
  components: { NoticeRollingItem, NoticeRollingDetail },
  data() {
    return {
      Boards: [],
      propCd: '1,2',
      bordNum: null,
      bordNo: '',
      noticeTitle: '',
      noticeSlides: true,
    }
  },
  computed: {
    BoardRolList: function () {
      return _.filter(
        this.Boards,
        BoardRolList => BoardRolList.notcWght === 'H',
      )
    },
  },
  watch: {
    Boards(Boards) {
      if (Boards) {
        this.initSwiper()
      }
    },
  },
  methods: {
    async search() {
      const res = await getBoardsList({
        schKeyword: this.searchText,
        pageNumber: this.pageNumber,
        propCd: this.propCd,
        bordNo: this.bordNo,
        pageSize: this.pageSize,
      })
      this.Boards = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber
      this.pageSize = res.pageable.pageSize
      this.no = this.totalElements - (this.currentPageIndex - 1) * this.pageSize
    },
    async updateReadCnt() {
      try {
        await getUpdateReadCnt({
          bordNo: this.bordNum,
        })
      } catch (e) {
        console.error(e)
      }
    },
    toggleAnswe(bordNo) {
      if (bordNo === null) {
        this.bordNum = null
        window.$('.container,.__contact,.sub-cont').css('position', 'relative')
        window.$('.mobileTopMenu').css('z-index', '1')
        window.$('.container').css('overflow-y', 'auto')
      } else {
        this.bordNum = bordNo
        window.$('.container,.__contact,.sub-cont').css('position', 'static')
        window.$('.mobileTopMenu').css('z-index', '0')
        window.$('.container').css('overflow-y', 'inherit')
      }
    },
    initSwiper() {
      this.$nextTick(() => {
        if (this.noticeSlides) {
          this.noticeSlides = new Swiper('#noticeSlides', {
            slidesPerView: 2,
            // spaceBetween: 10,
            autoHeight: true,
            loop: true,
            direction: 'vertical',
            autoplay: {
              delay: 3000,
              disableOnInteraction: true,
            },
          })
        }
        if (this.noticeSlides && this.BoardRolList.length <= 1) {
          this.noticeSlides = new Swiper('#noticeSlides', {
            slidesPerView: 1,
            autoHeight: true,
            loop: true,
            direction: 'vertical',
          })
        }
      })
    },
    noticeRoute() {
      this.$router.push('/Notice')
    },
  },
  async created() {
    this.$nextTick(() => {
      this.search()
    })
  },
}
</script>
<style>
.btn-save01 {
  width: 300px;
  height: 50px;
  display: flex;
  border-radius: 0.5rem;
  background: #ef7c4a;
  color: #fff;
  font-weight: bold;
  align-items: center;
  margin: 10px auto;
  justify-content: center;
}
.btn-save01 img {
  width: 20px;
  margin-left: 5px;
}
</style>
