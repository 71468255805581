<template>
  <div id="swiperNav">
    <div id="category_con">
      <h2 :class="{ on: islocalTab }" @click="filterTap('local')">
        지역<br class="subTapBr" /><span class="RegionText">{{
          RegionText
        }}</span>
      </h2>
      <h2 :class="{ on: isJobTab }" @click="filterTap('Job')">
        직종<br class="subTapBr" /><span class="JobTypText">{{
          JobTypText
        }}</span>
      </h2>
      <h2 :class="{ on: istermTab }" @click="filterTap('term')">
        공고유형<br class="subTapBr" /><span class="TermText">{{
          TermText
        }}</span>
      </h2>
      <h2
        :class="{ on: isEmployerMat }"
        @click="filterTap('Employer')"
        v-if="isEmployer"
      >
        나의공고<br class="subTapBr" /><span class="EmployerText">{{
          EmployerText
        }}</span>
      </h2>
      <h2
        :class="{ on: isEmployeeMat }"
        @click=";[filterTap('Employee'), logMat()]"
        v-else
      >
        나의매칭<br class="subTapBr" /><span class="EmployeeText">{{
          EmployeeText
        }}</span>
      </h2>
      <h2 :class="{ on: isNearMat }" @click=";[filterTap('Near'), logMat()]">
        주변공고<br class="subTapBr" /><span class="NearText">{{
          NearText
        }}</span>
      </h2>
    </div>
    <div id="category_list_con">
      <swiper v-show="islocalTab" class="local_list" :options="localOption">
        <swiper-slide v-for="(localItem, idx) in localList" :key="`li_${idx}`">
          <button
            type="button"
            class="btn _local"
            :value="localItem.name"
            :class="{ on: localItem.on }"
            @click=";[OnEvent($event, 'Local'), tabSeach()]"
          >
            {{ localItem.name }}
          </button>
        </swiper-slide>
      </swiper>
      <swiper v-show="isJobTab" class="local_list" :options="jopOption">
        <swiper-slide
          v-for="(jobTypeItem, idx) in jobTypeList"
          :key="`jti_${idx}`"
        >
          <button
            type="button"
            class="btn _job"
            id="jobTypeListBtn"
            :value="jobTypeItem.value"
            :class="{ on: jobTypeItem.on }"
            @click=";[OnEvent($event, 'job'), tabSeach()]"
          >
            {{ jobTypeItem.name }}
          </button>
        </swiper-slide>
      </swiper>
      <swiper v-show="istermTab" class="local_list" :options="jopOption">
        <swiper-slide>
          <button
            v-for="(termItem, idx) in termList"
            :key="`tmI_${idx}`"
            :value="termItem.value"
            type="button"
            class="btn _term"
            @click=";[OnEvent($event, 'term'), tabSeach()]"
          >
            {{ termItem.name }}
          </button>
        </swiper-slide>
      </swiper>
      <swiper v-show="isEmployerMat" class="local_list">
        <swiper-slide>
          <button
            type="button"
            class="btn _Employer"
            @click=";[OnEvent($event, 'Employer'), tabSeach()]"
          >
            전체
          </button>
          <button
            type="button"
            class="btn _Employer"
            @click=";[OnEvent($event, 'Employer'), tabSeach()]"
          >
            나의공고
          </button>
        </swiper-slide>
      </swiper>
      <swiper v-show="isEmployeeMat" class="local_list">
        <swiper-slide>
          <button
            type="button"
            class="btn _Employee"
            @click=";[OnEvent($event, 'Employee'), tabSeach()]"
          >
            전체
          </button>
          <button
            type="button"
            class="btn _Employee"
            @click=";[OnEvent($event, 'Employee'), tabSeach()]"
          >
            나의매칭
          </button>
        </swiper-slide>
      </swiper>
      <swiper v-show="isNearMat" class="local_list">
        <swiper-slide>
          <button
            type="button"
            class="btn _Near"
            @click=";[OnEvent($event, 'Near'), tabSeach()]"
          >
            전체
          </button>
          <button
            type="button"
            class="btn _Near"
            @click=";[OnEvent($event, 'Near'), tabSeach()]"
          >
            10Km
          </button>
          <button
            type="button"
            class="btn _Near"
            @click=";[OnEvent($event, 'Near'), tabSeach()]"
          >
            30Km
          </button>
          <button
            type="button"
            class="btn _Near"
            @click=";[OnEvent($event, 'Near'), tabSeach()]"
          >
            50Km
          </button>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { TERM_LIST_TWO, LOCAL_LIST, JOB_TYPE_LIST_TWO } from 'Configs/filter.js'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'
// import 'swiper/css/swiper.css'

export default {
  name: 'swiper-nav-example-loop',
  title: 'Loop mode / Infinite loop',
  components: { Swiper, SwiperSlide },
  computed: {
    ...mapGetters(['isLogin']),
  },
  data() {
    return {
      termList: JSON.parse(JSON.stringify(TERM_LIST_TWO)),
      localList: JSON.parse(JSON.stringify(LOCAL_LIST)),
      jobTypeList: JSON.parse(JSON.stringify(JOB_TYPE_LIST_TWO)),

      localOption: {
        slidesPerView: 13,
        spaceBetween: 10,
        loop: false,
      },
      jopOption: {
        slidesPerView: 3.5,
        spaceBetween: 10,
        loop: false,
      },
      islocalTab: true,
      isJobTab: false,
      istermTab: false,
      isEmployerMat: false,
      isEmployeeMat: false,
      isNearMat: false,

      RegionText: '',
      JobTypText: '',
      TermText: '',
      EmployerText: '',
      EmployeeText: '',
      NearText: '',
      windowWidth: window.innerWidth,
    }
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  created() {
    if (this.windowWidth < 1023) {
      this.localOption.slidesPerView = 5
    } else {
      this.localOption.slidesPerView = 13
    }
  },
  methods: {
    filterTap(is) {
      if (is === 'local') {
        this.islocalTab = true
        this.isJobTab = false
        this.istermTab = false
        this.isEmployeeMat = false
        this.isEmployerMat = false
        this.isNearMat = false
      } else if (is === 'Job') {
        this.isJobTab = true
        this.islocalTab = false
        this.istermTab = false
        this.isEmployeeMat = false
        this.isEmployerMat = false
        this.isNearMat = false
      } else if (is === 'term') {
        this.istermTab = true
        this.islocalTab = false
        this.isJobTab = false
        this.isEmployeeMat = false
        this.isEmployerMat = false
        this.isNearMat = false
      } else if (is === 'Employee') {
        this.isEmployeeMat = true
        this.islocalTab = false
        this.isJobTab = false
        this.istermTab = false
        this.isEmployerMat = false
        this.isNearMat = false
      } else if (is === 'Employer') {
        this.isEmployerMat = true
        this.islocalTab = false
        this.isJobTab = false
        this.istermTab = false
        this.isEmployeeMat = false
        this.isNearMat = false
      } else if (is === 'Near') {
        this.isNearMat = true
        this.islocalTab = false
        this.isJobTab = false
        this.istermTab = false
        this.isEmployeeMat = false
        this.isEmployerMat = false
      }
    },
    OnEvent(event, On) {
      var TargetOn = event.currentTarget
      var TargetOnText = TargetOn.innerText
      var LocalElement = document.getElementsByClassName('_local')
      var jobTypeElement = document.getElementsByClassName('_job')
      var termElement = document.getElementsByClassName('_term')
      var EmployerElement = document.getElementsByClassName('_Employer')
      var EmployeeElement = document.getElementsByClassName('_Employee')
      var NearElement = document.getElementsByClassName('_Near')
      if (On === 'Local') {
        this.schRegion = event.currentTarget.value
        window.$(LocalElement).removeClass('on')
        window.$(TargetOn).addClass('on')
        window.$('.RegionText').addClass('on')
        this.RegionText = this.schRegion
      } else if (On === 'job') {
        this.schPosition = event.currentTarget.value
        window.$(jobTypeElement).removeClass('on')
        window.$(TargetOn).addClass('on')
        window.$('.JobTypText').addClass('on')
        this.JobTypText = TargetOnText
      } else if (On === 'term') {
        this.schWorkPeriod = event.currentTarget.value
        window.$(termElement).removeClass('on')
        window.$(TargetOn).addClass('on')
        window.$('.TermText').addClass('on')
        this.TermText = TargetOnText
      } else if (On === 'Employer') {
        this.schCompanyId = event.currentTarget.innerText
        window.$(EmployerElement).removeClass('on')
        window.$(TargetOn).addClass('on')
        window.$('.EmployerText').addClass('on')
        this.EmployerText = TargetOnText
      } else if (On === 'Employee') {
        this.schPushStatus = event.currentTarget.innerText
        window.$(EmployeeElement).removeClass('on')
        window.$(TargetOn).addClass('on')
        window.$('.EmployeeText').addClass('on')
        this.EmployeeText = TargetOnText
      } else if (On === 'Near') {
        this.distance = event.currentTarget.innerText
        window.$(NearElement).removeClass('on')
        window.$(TargetOn).addClass('on')
        window.$('.NearText').addClass('on')
        this.NearText = TargetOnText
      }
    },
    logMat() {
      if (this.isLogin === false) {
        location.href = '/login'
        setTimeout()
      }
    },
    tabSeach() {
      //지역
      var localListText = this.schRegion
      if (localListText == '전체' || localListText == undefined)
        localListText = ''
      var jobtypeListText = this.schPosition
      if (jobtypeListText == '99' || jobtypeListText == undefined)
        jobtypeListText = ''
      var termListText = this.schWorkPeriod
      if (termListText == 'FULL' || termListText == undefined) termListText = ''
      var EmployerText = this.schCompanyId
      if (EmployerText == '나의공고') EmployerText = this.userId
      else if (EmployerText == '전체' || EmployerText == undefined)
        EmployerText = ''
      var EmployeeText = this.schPushStatus
      if (EmployeeText == '나의매칭') EmployeeText = 'SCALL'
      else if (EmployeeText == '전체' || EmployeeText == undefined)
        EmployeeText = ''
      var distanceText = this.distance
      if (distanceText == '전체' || distanceText == undefined) distanceText = ''
      const query = {
        schRegion: localListText,
        schPosition: jobtypeListText,
        schWorkPeriod: termListText,
        schCompanyId: EmployerText,
        schPushStatus: EmployeeText,
        distance: distanceText.replace('Km', ''),
      }
      console.log('search', query)
      this.$emit('search', query)
    },
  },
  updated() {
    var targetButton = event.currentTarget
    var ButtonText = targetButton.innerText
    var targetSpan = window.$('span:contains(' + ButtonText + ')')
    var targetSpanOn = targetSpan.hasClass('on')
    var targetH2 = targetSpan.parent('h2')
    if (this.windowWidth < 1023) {
      if (targetSpanOn === true) {
        window.$(targetH2).css('line-height', '30px')
      }
    }
  },
}
</script>

<style scoped>
.subTapBr {
  display: none;
}
@media (max-width: 1024px) {
  .subTapBr {
    display: block;
  }
}
.swiper-slide {
  line-height: 28px;
  height: 30px;
  width: auto !important;
}
button {
  border-radius: 15px;
  border: 1px solid #bebebe;
  height: 100%;
  padding: 0 15px;
  margin: 0 5px;
  width: auto;
}
button.on {
  background-color: #ef7c4a;
  color: white;
  border: 1px solid #fff;
}
.RegionText,
.JobTypText,
.TermText,
.EmployerText,
.EmployeeText,
.NearText {
  background-color: #ef7c4a;
  color: white;
  border: 1px solid #fff;
  border-radius: 15px;
  padding: 3px 15px;
  margin: 0 5px;
  display: none;
}
.RegionText.on,
.JobTypText.on,
.TermText.on,
.EmployerText.on,
.EmployeeText.on,
.NearText.on {
  display: inline;
}
</style>
